<template>
  <svg-querying v-bind="$attrs" />
</template>

<script>
import SvgQuerying from '@/assets/svg/querying.svg'

export default {
  components: {
    SvgQuerying
  }
}
</script>
